import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql } from "gatsby"
import travelers from "../../static/travelers.png";

class Opinions extends Component {
  render(data) {
    const { t } = this.props;
    return <div className={`py-4 mt-5 position-relative ${this.props.fondo ? 'fondo-gris' : ''}`}>
      {this.props.fondo &&
        <div className="llamita llamita-superior d-lg-none"></div>
      }
      <div className="container-principal">
          <StaticQuery query={graphql`
            query OpinionsQuery {
              site {
                siteMetadata {
                  opinions {
                    stars
                    date
                    text
                  }
                  tripAdvisor
                }
              }
            }
          `}
          render={data => (<>
              <div className="cabecera d-flex mb-lg-5">
                <div className="travelers pl-lg-2">
                  <a href={data.site.siteMetadata.tripAdvisor} target="_blank" rel="noreferrer">
                    <img src={travelers} alt=""/>
                  </a>
                </div>
                <div className="texto">
                  {this.props.fondo &&
                  <div className="position-relative">
                    <div className="llamita d-none d-lg-block"></div>
                  </div>
                  }
                  <h2 className={`mt-4 ${this.props.fondo ? 'mt-lg-0' : 'mt-5'}`}>{t("opiniones")}</h2>
                </div>
                <div className="pr-lg-8"></div>
              </div>
          <div className="row">
            {
              data.site.siteMetadata.opinions.map((opinion, i) => {
                return <div className="col-lg px-5 mt-5 mt-lg-0" key={`opinion_${i}`}>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <i className={`fa fa-star ${t(opinion.stars) > 0 ? 'text-red' : 'text-white'}`} aria-hidden={true}></i>
                      <i className={`fa fa-star ${t(opinion.stars) > 1 ? 'text-red' : 'text-white'}`} aria-hidden={true}></i>
                      <i className={`fa fa-star ${t(opinion.stars) > 2 ? 'text-red' : 'text-white'}`} aria-hidden={true}></i>
                      <i className={`fa fa-star ${t(opinion.stars) > 3 ? 'text-red' : 'text-white'}`} aria-hidden={true}></i>
                      <i className={`fa fa-star ${t(opinion.stars) > 4 ? 'text-red' : 'text-white'}`} aria-hidden={true}></i>
                    </div>
                    <strong>{t(opinion.date)}</strong>
                  </div>
                  <p className="text-center m-0">{t(opinion.text)}</p>
                </div>
              })
            }
          </div>
          </>
          )}/>
      </div>
    </div>
  }
}
export default withTranslation('Opinions')(Opinions);
