import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql, Link } from "gatsby"
import { setPathSlash } from "./lib/utils";

class IndexExperiences extends Component {
  render() {
		const { t } = this.props;
		const language = this.props.i18n.language;

    const onClickCategory = (category) => {			
      window.addDataLayer({
        event: "home_categorias_click",
        selected_category: category
      });
    }

    return <div className="index-experiences py-5 text-center container-principal">
      <hr/>
      <h2 className="mt-5 mb-4 font-weight-500">{t("titulo")}</h2>
      <div className="row experiencias-inicio">
        <StaticQuery query={graphql`
          query IndexExperiencesQuery {
            site {
              siteMetadata {
                menuLinks {
                  name
                  image
                  hiddenIndex
                  children {
                    experience
                    article
                  }
                }
              }
            }
            allLocalizedArticle(sort: {fields: [section, lang, order], order: ASC}) {
              edges {
                node {
                  articleId
                  name
                  path
                  lang
                  section
                }
              }
            }
          }
        `}
        render={data => (<>
          {
            data.site.siteMetadata.menuLinks.map(menu => {
              if (menu.children && !menu.hiddenIndex) {
                let actividades = 0;
                menu.children.forEach((child) => {
                  if (child.experience) {
                    actividades++;
                  }
                });
                const thisArticle = data.allLocalizedArticle.edges.find(article => ((article.node.articleId === menu.children[0].article) && (article.node.lang === language)));
                return thisArticle ?
                  <Link 
                    to={setPathSlash(thisArticle.node.path)}
                    className="col-lg-4 mb-4" key={menu.name}
                    onClick={() => {onClickCategory(thisArticle.node.path.split('/').slice(-1).pop());}}
                  >
                    <div style={{backgroundImage: `url(/${menu.image})`}}>
                      <div>
                        <h3 className="m-0 p-2 text-white text-uppercase font-weight-bold">{t(menu.name)}</h3>
                        <p className="m-0 p-2">{actividades} {t("actividades")}</p>
                      </div>
                    </div>
                  </Link>
                :
                  null;
              } else {
                return null;
              }
            })
          }
        </>
        )}/>
      </div>
      <h2 className="mt-3 mb-4 font-weight-500">{t("vienes")}</h2>
      <p>{t("footText")}</p>
      <hr className="mt-5" />
    </div>
  }
}

export default withTranslation('IndexExperiences')(IndexExperiences);
