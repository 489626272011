import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { setPathSlash } from "./lib/utils";

class Button extends Component {
  render() {
    return <StaticQuery query={graphql`
      query ButtonQuery {
        allLocalizedArticle {
          edges {
            node {
              path
              articleId
              lang
            }
          }
        }
      }
    `}
    render={data => (<>
      {
        data.allLocalizedArticle.edges.map((article, i) => {
          if (article.node.lang === this.props.i18n.language && article.node.articleId === Number(this.props.id)) {
            return <Link 
                    key={`button-${i}`} 
                    className={`btn button-red ${this.props.className ? this.props.className : ''}`} 
                    to={setPathSlash(article.node.path)}
                    onClick={this.props.onClickCallback ? () => {this.props.onClickCallback(article.node.path.split('/').slice(-1).pop());} : null} 
                   >

                    {this.props.text}
                  </Link>
          } else {
            return null;
          }
        })
      }
    </>
    )}/>
  }
}

export default withTranslation('Button')(Button);