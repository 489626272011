import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { withTranslation } from 'gatsby-plugin-react-i18next';
import hierro from "../../static/hierro.svg";
import hierroRojo from "../../static/hierro-rojo.svg";
import palma from "../../static/palma.svg";
import palmaRojo from "../../static/palma-rojo.svg";
import gomera from "../../static/gomera.svg";
import gomeraRojo from "../../static/gomera-rojo.svg";
import tenerife from "../../static/tenerife.svg";
import tenerifeRojo from "../../static/tenerife-rojo.svg";
import granCanaria from "../../static/gran-canaria.svg";
import granCanariaRojo from "../../static/gran-canaria-rojo.svg";
import fuerteventura from "../../static/fuerteventura.svg";
import fuerteventuraRojo from "../../static/fuerteventura-rojo.svg";
import lanzarote from "../../static/lanzarote.svg";
import lanzaroteRojo from "../../static/lanzarote-rojo.svg";
import { setPathSlash } from "./lib/utils";

class Selector extends Component {
  render() {
    const { t } = this.props;
    const language = this.props.i18n.language;
    
    return <div className="container-principal">
      <h2 className="text-center mt-4 pt-4">{t("EligeIsla")}</h2>
      <p className="text-center my-3">{t("HazClic")}</p>
      <div className="row">
        <div className="d-none d-lg-block col-1"></div>
        <div className="col">
        <svg width="100%" height="100%" viewBox="0 0 1180 515" preserveAspectRatio="xMinYMin meet">
          <StaticQuery query={graphql`
            query SelectorQuery {
              site {
                siteMetadata {
                  islandSelector {
                    fuerteventura
                    gomera
                    granCanaria
                    hierro
                    lanzarote
                    palma
                    tenerife
                  }
                }
              }
              allLocalizedArticle {
                edges {
                  node {
                    articleId
                    path
                    lang
                  }
                }
              }
            }
          `}
          render={data => (<>
            {
              data.site.siteMetadata.islandSelector.map((thisSelector, i) => {
                const articleHierro = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.hierro && article.node.lang === language);
                const articlePalma = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.palma && article.node.lang === language);
                const articleGomera = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.gomera && article.node.lang === language);
                const articleTenerife = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.tenerife && article.node.lang === language);
                const articleGranCanaria = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.granCanaria && article.node.lang === language);
                const articleFuerteventura = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.fuerteventura && article.node.lang === language);
                const articleLanzarote = data.allLocalizedArticle.edges.find((article) => article.node.articleId === thisSelector.lanzarote && article.node.lang === language);
                return <React.Fragment key={`selector-${i}`}>
                  <Link to={setPathSlash(articleHierro ? articleHierro.node.path : "/")}>
                    <image className="gris" x="0" y="435" width="83" xlinkHref={hierro}/>
                    <image className="rojo" x="0" y="435" width="83" xlinkHref={hierroRojo}/>
                    <text x="85" y="475">{t("hierro")}</text>
                  </Link>

                  <Link to={setPathSlash(articlePalma ? articlePalma.node.path : "/")}>
                    <image className="gris" x="50" y="145" width="85" xlinkHref={palma}/>
                    <image className="rojo" x="50" y="145" width="85" xlinkHref={palmaRojo}/>
                    <text x="145" y="190">{t("palma")}</text>
                  </Link>

                  <Link to={setPathSlash(articleGomera ? articleGomera.node.path : "/")}>
                    <image className="gris" x="180" y="335" width="77" xlinkHref={gomera}/>
                    <image className="rojo" x="180" y="335" width="77" xlinkHref={gomeraRojo}/>
                    <text x="165" y="435">{t("gomera")}</text>
                  </Link>

                  <Link to={setPathSlash(articleTenerife ? articleTenerife.node.path : "/")}>
                    <image className="gris" x="285" y="225" width="225" xlinkHref={tenerife}/>
                    <image className="rojo" x="285" y="225" width="225" xlinkHref={tenerifeRojo}/>
                    <text x="330" y="230">{t("tenerife")}</text>
                  </Link>

                  <Link to={setPathSlash(articleGranCanaria ? articleGranCanaria.node.path : "/")}>
                    <image className="gris" x="575" y="325" width="140" xlinkHref={granCanaria}/>
                    <image className="rojo" x="575" y="325" width="140" xlinkHref={granCanariaRojo}/>
                    <text x="615" y="295">{t("granCanaria")}</text>
                  </Link>

                  <Link to={setPathSlash(articleFuerteventura ? articleFuerteventura.node.path : "/")}>
                    <image className="gris" x="890" y="160" width="192" xlinkHref={fuerteventura}/>
                    <image className="rojo" x="890" y="160" width="192" xlinkHref={fuerteventuraRojo}/>
                    <text x="800" y="230">{t("fuerteventura")}</text>
                  </Link>

                  <Link to={setPathSlash(articleLanzarote ? articleLanzarote.node.path : "/")}>
                    <image className="gris" x="1050" y="0" width="125" xlinkHref={lanzarote}/>
                    <image className="rojo" x="1050" y="0" width="125" xlinkHref={lanzaroteRojo}/>
                    <text x="910" y="95">{t("lanzarote")}</text>
                  </Link>
                </React.Fragment>
              })
            }
          </>
          )}/>
        </svg>
        </div>
        <div className="d-none d-lg-block col-1"></div>
      </div>
    </div>
  }
}
export default withTranslation('Selector')(Selector);