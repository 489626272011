import React from "react"
import { useLocation } from  "@reach/router";
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import banner from "../../static/banner.jpg";
import martin from "../../static/martin.jpg";
import IndexExperiences from '../components/IndexExperiences';
import Opinions from "../components/Opinions";
import Button from "../components/Button";
import Selector from "../components/Selector";
import iconoCancelamiento from "../../static/icono-cancelamiento.svg";
import iconoJeep from "../../static/icono-jeep.svg";
import iconoViajeros from "../../static/icono-viajeros.svg";
import iconoBocadillo from "../../static/icono-bocadillos.svg";
import iconoPareja from "../../static/icono-pareja.svg";
import iconoEdificio from "../../static/icono-edificio.svg";
import iconoCubiertos from "../../static/icono-cubiertos.svg";
import iconoIsla from "../../static/icono-isla.svg";
import favicon from "../../static/favicon.svg"
import { siteMetadata } from "../../gatsby-config";
import { setPathCanonical, setPathSlash } from "../components/lib/utils";

export default function Home({ data }) {
  const { t } = useTranslation('Index');
  const { title, description } = useSiteMetadata();
  const location = useLocation();
  const { language } = React.useContext(I18nextContext);
  const onClickConocenos = () => {
		window.addDataLayer({
			event: "click_boton_conocenos",
			button_location: "home"
		});
	}

  return (
    <Layout>
      <Helmet>
        <html lang={language}/>
        <title>{t(title)}</title>
        <link rel="icon" href={favicon}/>
        <meta name="title" content={t(title)}/>
        <meta name="og:title" content={t(title)}/>
        <meta name="og:site_name" content={title}/>
        <meta name="description" content={t(description)}/>
        <meta name="og:image" content={location.origin + banner}/>
        <meta name="og:type" content="website"/>
        <meta name="og:url" content={location.href}/>
        <meta name="og:description" content={t(description)}/>
        {siteMetadata.languages.map((language, key) => <link rel="alternate" key={language} hrefLang={`${language}`}
                                                             href={setPathSlash(`${siteMetadata.siteUrl}/${language}/`)}/>)}
        <link rel="canonical" href={setPathCanonical(location.href)}/>
      </Helmet>
      <article className="index">
        <div className="banner" style={{backgroundImage: `url(${banner})`}}>
          <h1>{t("EspecialistasExcursionesCanarias")}</h1>
          <div className="subtitulo d-flex d-md-block flex-column">
            <span className="barra">{t("4x4")}</span>
            <span className="guion d-lg-none">-</span>
            <span className="barra">{t("Monovolumen")}</span>
            <span className="guion d-lg-none">-</span>
            <span className="barra">{t("GruposReducidos")}</span>
            <span className="guion d-lg-none">-</span>
            <span className="barra">{t("ExperienciasMedida")}</span>
          </div>
          <div className="llamita"></div>
        </div>
        <Selector />
        <IndexExperiences />
        <div className="experience-feature container-principal mb-4">
          <h2 className="text-center mb-4">{t("experience-feature")}</h2>
          <div className="row">
            <div className="col-lg-1 col-xl-2"></div>
            <div className="col-lg">
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoCancelamiento} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature1")}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoJeep} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature2")}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoViajeros} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature3")}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoBocadillo} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature4")}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoPareja} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature5")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg">
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoEdificio} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature6")}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoCubiertos} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature7")}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 text-center">
                  <img src={iconoIsla} alt=""></img>
                </div>
                <div className="col pl-1 font-weight-light">
                  <p className="d-inline">{t("feature8")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-xl-2"></div>
          </div>
        </div>
        <Opinions fondo={true} />
        <div className="conocenos container-principal mt-lg-5 pt-5">
          <h3 className="text-center font-weight-500 mb-4 text-gray">{t("QuienDetras")}</h3>
          <div className="row">
            <div className="col-lg-5 text-center text-lg-right">
              <img className="max-height-250" style={{objectFit: "contain", borderRadius: "10px"}} src={martin} alt={t("FotoMartin")}></img>
            </div>
            <div className="col-lg-5 ml-lg-4 text-center text-lg-left">
              <p className="text-red font-weight-500 mt-3 mt-lg-0 mb-3">{t("NegocioFamiliar")}</p>
              <p className="font-weight-light">{t("SoyCesar")}</p>
              <Button id={188} text={t("Conocenos")} onClickCallback={onClickConocenos} />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}
